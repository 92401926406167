import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiProvider } from "../ApiProvider";
import { getInitialState } from "../helpers";
import { toast } from "sonner";
import { ActiveSeatGeekListing, EventListing, EventListingsResponse } from "../marketplace/seatgeek/getActiveListingsSlice";
import { RootState } from "../store";

type TSeasonsPayload = {
	listingId: number;
};

export interface ISeasonItem {
	Listing_ID: number;
	Event_Headliner_ID: number;
	Event_Headliner: string;
	Cat_Type: string;
	SeatGeek_Event_ID: number;
	Event_ID: number;
	Event_Name: string;
	Event_Date: string;
	Event_Time: string;
	Venue_Name: string;
	Section: string;
	Row: string;
	Quantity: number;
	Item_Price: number;
	SeatGeek_Listing_ID: number;
	SG_eDelivery: true;
	Company_Name: string;
	Is_AutoPriced: false;
	Floor_Price: number | null;
	Ceiling_Price: number | null;
	Compare_To_Everything: boolean | null;
	Compare_Against_Own_Inventory: boolean | null;
	Allow_Price_Increase: boolean | null;
}

type TActiveListings = Record<number, Array<EventListing>>;

type TGetSeasonsReturn = {
	eventId: number;
	data: EventListingsResponse["Listings"];
};

export type TSeasons = {
	seasons: Array<ISeasonItem> | null;
	selectedIds: Array<number>;
	selectedRowId: number | null;
	isActiveListingsLoading: boolean;
	activeListings: TActiveListings;
};

type ISeasonItemPartial = {
	Listing_ID: ISeasonItem["Listing_ID"];
} & Partial<Omit<ISeasonItem, "Listing_ID">>;

const initialState = getInitialState<TSeasons>({
	seasons: null,
	selectedIds: [],
	selectedRowId: null,
	activeListings: {},
	isActiveListingsLoading: false,
});

export const getSeasonEventData = createAsyncThunk<TGetSeasonsReturn, number, { rejectValue: string }>(
	"getSeasonInventory/getSeasonEventData",
	async (payload, { rejectWithValue }) => {
		try {
			const response = await ApiProvider.default.get<EventListingsResponse>(
				`/api/Marketplace/SeatGeek/Event/${payload}/Listings`,
			);
			return {
				eventId: payload,
				data: response.Listings,
			};
		} catch (error) {
			toast.warning("Could not fetch Event Data");
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			return rejectWithValue("Unknown error");
		}
	},
);

export const getSeasonData = createAsyncThunk<Array<ISeasonItem>, TSeasonsPayload, { rejectValue: string }>(
	"getSeasonInventory/getSeasonData",
	async ({ listingId }, { rejectWithValue }) => {
		try {
			const response = await ApiProvider.default.get<Array<ISeasonItem>>(
				`/api/AutoPricer/QueryInventorySeason?listingId=${listingId}`,
			);
			return response;
		} catch (error) {
			toast.warning("Could not fetch seasons");
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			return rejectWithValue("Unknown error");
		}
	},
);

export const getSeasonInventory = createSlice({
	name: "getSeasonInventory",
	initialState,
	reducers: {
		removeSeasonsData: (state) => {
			state.data.seasons = null;
			state.data.selectedIds = [];
			state.data.selectedRowId = null;
			state.version = 0;
			state.error = null;
		},
		editSeasonsData: (state, action: PayloadAction<ISeasonItemPartial>) => {
			if (!state.data.seasons) return;

			state.data.seasons = state.data.seasons.map((item) =>
				item.Listing_ID !== action.payload.Listing_ID ? item : { ...item, ...action.payload },
			);
		},
		setSelectedIds: (state, action: PayloadAction<Array<number>>) => {
			state.data.selectedIds = action.payload;
		},
		setSelectedRowId: (state, action: PayloadAction<number>) => {
			state.data.selectedRowId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSeasonData.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.data.seasons = null;
			})
			.addCase(getSeasonData.fulfilled, (state, action: PayloadAction<Array<ISeasonItem>>) => {
				state.loading = false;
				state.data.seasons = action.payload;
				state.version++;
				state.init = true;
			})
			.addCase(getSeasonData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload ?? "Failed to fetch seasons";
				state.data.seasons = null;
			})
			.addCase(getSeasonEventData.pending, (state) => {
				state.data.isActiveListingsLoading = true;
			})
			.addCase(getSeasonEventData.fulfilled, (state, action) => {
				state.data.isActiveListingsLoading = false;
				state.data.activeListings[action.payload.eventId] = action.payload.data;
			});
	},
});

const selectSeasonRowId = (state: RootState) => state.getSeason.data.selectedRowId;
const selectAllSeasonEventData = (state: RootState) => state.getSeason.data.activeListings;

const selectedSeasonEvent = createSelector([selectSeasonRowId, selectAllSeasonEventData], (id, data) => {
	if (!id) return [];
	const selectedEvent = data[id] ?? [];
	return selectedEvent;
});

export const selectSeasonActiveListing = createSelector(
	[selectedSeasonEvent],
	(activeListings) =>
		activeListings?.map<ActiveSeatGeekListing>((eventListing) => ({
			oldSeatGeekId: eventListing.id,
			seatGeekListingId: eventListing.sglid,
			section: eventListing.s,
			row: eventListing.r.length > 0 ? eventListing.r.toUpperCase() : eventListing.s.toUpperCase(),
			quantity: eventListing.q,
			price: eventListing.bp,
			match: eventListing.mtc,
			splits: eventListing.sp,
			deliveryMethod: eventListing.dm,
			instantDelivery: eventListing.idl,
			stockType: eventListing.st,
		})) ?? [],
);
