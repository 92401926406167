import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SELECTED_SECTION_FILL_PAINT, USERS_SECTION_FILL_PAINT } from "@src/components/map-view/map.constants";
import { PolygonFeature } from "@src/components/map-view/map.types";
import {
	createFillLayer,
	groupFeaturesBySection,
	normalizeName,
	queryMapPolygonFeatures,
} from "@src/components/map-view/map.utils";
import { getComparableItemsFromDictionary, getRows, getSections, IIgnoredListing } from "@src/components/utils/autopricerUtils";
import { ActiveSeatGeekListing, MatchStatus } from "@src/store/marketplace/seatgeek/getActiveListingsSlice";
import { IPOSSettings } from "@src/store/pos/getPOSSettingsSlice";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import { RootState } from "@src/store/store";
import {
	convertMapToRecord,
	difference,
	getUniqueArrayValues,
	groupByProperty,
	intersection,
	mapDictionaryKeysToValues,
	mapProperties,
	sortCompareFnFactory,
	toggleArrayElement,
	transformMapValues,
	union,
} from "@src/utils/array.utils";
import { FillLayer, MapboxMap } from "react-map-gl";

import { calculatePossibleSplits } from "@src/components/utils/lowestComparablesUtils";
import {
	ExcludeItemLabeEnum,
	TExcludeMethodItem,
} from "@src/pages/home/children/pricing/components/manage-inventory/autopricer/ui/excludeMethodItems";
import { TGroupName } from "@src/pages/home/children/pricing/components/manage-inventory/autopricer/ui/ExcludeMethods";
import { ApiProvider } from "@src/store/ApiProvider";
import { createAppAsyncThunk } from "@src/store/hooks";
import { pricingActions } from "..";
import { selectSeasonActiveListing } from "./getSeasonInventory";
import { selectViewSeasonLowestComparables } from "../pricing/selectionSlice";

export interface ToggleExclusionsPayload {
	type: TGroupName;
	payload: TExcludeMethodItem;
}

export interface ComparableItem {
	name: string;
	minPrice: number;
	listings: number;
	tickets: number;
}

export type FeaturesDictionary = Record<string, PolygonFeature>;
export type ListingsDictionary = Record<string, Array<ActiveSeatGeekListing>>;
export type SectionsByRow = Record<string, Array<string>>;
export type SaveRuleStatus = "idle" | "pending" | "saved" | "error";

export interface IAutoPricerState {
	listingsBySection: ListingsDictionary;
	featuresBySection: FeaturesDictionary;
	sectionsByRow: SectionsByRow;
	comparableSections: Array<ComparableItem>;
	ignoredOldSeatGeekIds: Array<string>;
	selectedSectionNames: Array<string>;
	selectedRowNames: Array<string>;
	usersSectionNames: Array<string>;
	compareAgainstConsignors: boolean;
	floorPricePercentage: number;
	ceilingPricePercentage: number;
	singleSplitsMapping: Array<number>;
	matchAll: boolean;
	saveRuleStatus: SaveRuleStatus;
	traitExclusions: Array<TExcludeMethodItem>;
	deliveryExclusions: Array<TExcludeMethodItem>;
	allowPriceIncrease: boolean;
}

const initialState: IAutoPricerState = {
	listingsBySection: {},
	featuresBySection: {},
	sectionsByRow: {},
	comparableSections: [],
	ignoredOldSeatGeekIds: [],
	selectedSectionNames: [],
	selectedRowNames: [],
	usersSectionNames: [],
	compareAgainstConsignors: false,
	floorPricePercentage: 90,
	ceilingPricePercentage: 300,
	singleSplitsMapping: [],
	matchAll: false,
	saveRuleStatus: "idle",
	traitExclusions: [],
	deliveryExclusions: [],
	allowPriceIncrease: true,
};

export interface InitializeAutopricerStatePayload {
	map: MapboxMap;
	activeSeatGeekListings: Array<ActiveSeatGeekListing>;
	eventInventoryData: Array<EventInventoryRecord>;
	POSSettings: IPOSSettings | null;
	quantity: number;
}

export interface LoadNewRulePayload {
	CeilingPrice?: number;
	FloorPrice?: number;
	TicketSplit?: Array<number>;
	Rows?: Array<string>;
	Sections?: Array<string>;
	CompareToEverything?: boolean;
	CompareAgainstOwnInventory?: boolean;
	Ignored_Listings?: Array<IIgnoredListing>;
	Delivery_Exclusions?: Array<TExcludeMethodItem>;
	TraitExclusions?: Array<TExcludeMethodItem>;
	NoComparablesNotification?: boolean;
	AllowPriceIncrease?: boolean;
	AmountUnder?: number;
}

export interface AutoPricerItemHistory {
	ItemId: number;
	ListingId: number;
	Section: string;
	Row: string;
	Seat: string;
	UserName: string;
	Activity: string;
	ActivityType: string;
	CreatedDate: string;
}

export const getItemPriceHistory = createAsyncThunk<Array<AutoPricerItemHistory>, string>(
	"autopricer/getItemHistory",
	async (payload, { rejectWithValue }) => {
		try {
			const data = await ApiProvider.default.get<Array<AutoPricerItemHistory>>(`/api/System/ViewItemHistory?${payload}`);
			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const INACTIVE_LISTING_ID = -1;

const autoPricerSlice = createSlice({
	name: "autopricer",
	initialState,
	reducers: {
		initializeAutopricerState: (state, action: PayloadAction<InitializeAutopricerStatePayload>) => {
			const { map, activeSeatGeekListings, eventInventoryData, POSSettings, quantity } = action.payload;

			const normalizedActiveSeatGeekListings = mapProperties(activeSeatGeekListings, ["section", "row"], normalizeName);

			const listingsBySection = groupByProperty(normalizedActiveSeatGeekListings, "section");
			const listingsByRow = groupByProperty(normalizedActiveSeatGeekListings, "row");

			const sectionsByRow = transformMapValues(listingsByRow, (listings) => listings.map(({ section }) => section));

			const allMapboxSectionFeatures = queryMapPolygonFeatures(undefined, map, "section");
			const allMapboxRowFeatures = queryMapPolygonFeatures(undefined, map, "row");

			allMapboxSectionFeatures.forEach((mapboxSectionFeature) => {
				const normalizedSectionName = normalizeName(mapboxSectionFeature.properties.name);

				if (listingsBySection.has(normalizedSectionName)) return;

				listingsBySection.set(normalizedSectionName, []);
			});

			allMapboxRowFeatures.forEach((mapboxRowFeature) => {
				const originalSectionName = mapboxRowFeature.properties.parent_name;

				if (!originalSectionName) return;

				const normalizedSectionName = normalizeName(originalSectionName);
				const normalizedRowName = normalizeName(mapboxRowFeature.properties.name);

				const sectionListings = listingsBySection.get(normalizedSectionName);

				const inactiveListing: ActiveSeatGeekListing = {
					oldSeatGeekId: "",
					seatGeekListingId: INACTIVE_LISTING_ID,
					section: normalizedSectionName,
					row: normalizedRowName,
					quantity: 0,
					price: 0,
					match: 0,
					splits: [],
					instantDelivery: false,
				};

				if (!sectionListings) {
					listingsBySection.set(normalizedSectionName, [inactiveListing]);
				} else {
					const sectionRows = sectionListings.map(({ row }) => row);

					if (sectionRows.includes(normalizedRowName)) return;

					listingsBySection.set(normalizedSectionName, [...sectionListings, inactiveListing]);
				}

				const rowSections = sectionsByRow.get(normalizedRowName);

				sectionsByRow.set(normalizedSectionName, union(rowSections ?? [], normalizedSectionName));
			});

			const listingBySectionRecord = convertMapToRecord(listingsBySection);

			state.listingsBySection = listingBySectionRecord;

			state.featuresBySection = convertMapToRecord(groupFeaturesBySection(allMapboxSectionFeatures));

			state.sectionsByRow = convertMapToRecord(sectionsByRow);

			state.comparableSections = getComparableItemsFromDictionary(listingBySectionRecord);

			state.usersSectionNames = getUniqueArrayValues(eventInventoryData.map(({ Section }) => normalizeName(Section)));

			state.saveRuleStatus = "idle";

			state.compareAgainstConsignors = !!POSSettings?.Compare_Against_Own_Inventory;
			state.floorPricePercentage = POSSettings?.Floor_Price_Percentage ?? 90;
			state.ceilingPricePercentage = POSSettings?.Ceiling_Price_Percentage ?? 300;
			state.singleSplitsMapping = calculatePossibleSplits(POSSettings?.Custom_Split_Mapping, quantity);
		},
		selectSectionOnMap: (state, action: PayloadAction<string>) => {
			const sectionName = action.payload;

			const selectedSectionNames = toggleArrayElement(state.selectedSectionNames, sectionName);

			const allowedRowNames = getRows(state.listingsBySection, selectedSectionNames);

			state.selectedSectionNames = selectedSectionNames;

			state.selectedRowNames = intersection(allowedRowNames, state.selectedRowNames);
		},
		saveSelectedSections: (state, action: PayloadAction<Array<string>>) => {
			const selectedSectionNames = action.payload;

			const allowedRowNames = getRows(state.listingsBySection, selectedSectionNames);

			state.selectedSectionNames = selectedSectionNames;

			state.selectedRowNames = intersection(allowedRowNames, state.selectedRowNames);
		},
		saveSelectedRows: (state, action: PayloadAction<Array<string>>) => {
			const selectedRowNames = action.payload;

			state.selectedRowNames = selectedRowNames;

			if (state.selectedSectionNames.length > 0) return;

			const mappedRowSections = mapDictionaryKeysToValues(state.sectionsByRow, selectedRowNames).flat();

			state.selectedSectionNames = getUniqueArrayValues(mappedRowSections);
		},
		toggleMatchAll: (state) => {
			const newMatchAllValue = !state.matchAll;

			const selectedSectionNames = newMatchAllValue ? getSections(state.listingsBySection) : [];
			const selectedRowNames = newMatchAllValue ? getRows(state.listingsBySection) : [];

			state.selectedSectionNames = selectedSectionNames;
			state.selectedRowNames = selectedRowNames;
			state.matchAll = newMatchAllValue;
		},
		ignoreListing: (state, action: PayloadAction<string>) => {
			const { ignoredOldSeatGeekIds } = state;
			const oldSeatGeekId = action.payload;

			state.ignoredOldSeatGeekIds = toggleArrayElement(ignoredOldSeatGeekIds, oldSeatGeekId);
		},
		toggleCompareAgainstConsignors: (state) => {
			state.compareAgainstConsignors = !state.compareAgainstConsignors;
		},
		resetMapState: (state) => {
			state.selectedSectionNames = [];
			state.selectedRowNames = [];
		},
		resetAutopricerState: () => {
			return initialState;
		},
		toggleSingleSplitsMapping: (state, action: PayloadAction<number>) => {
			const currentSplits = state.singleSplitsMapping;
			const isIncluded = currentSplits.includes(action.payload);

			if (isIncluded) {
				state.singleSplitsMapping = currentSplits.filter((x) => x !== action.payload);
				return;
			}
			state.singleSplitsMapping.push(action.payload);
		},
		bulkEditAutoPricer: (state, action: PayloadAction<LoadNewRulePayload>) => {
			const {
				Rows,
				Sections,
				CompareToEverything,
				CompareAgainstOwnInventory,
				Ignored_Listings,
				TicketSplit,
				Delivery_Exclusions,
				AllowPriceIncrease,
				TraitExclusions,
			} = action.payload;

			const ignoredListings = Ignored_Listings?.map((x) => x.SeatGeekId) ?? state.ignoredOldSeatGeekIds;

			state.matchAll = CompareToEverything ?? state.matchAll;
			state.selectedSectionNames = Sections ?? state.selectedSectionNames;
			state.selectedRowNames = Rows ?? state.selectedRowNames;
			state.compareAgainstConsignors = CompareAgainstOwnInventory ?? state.compareAgainstConsignors;
			state.ignoredOldSeatGeekIds = ignoredListings;
			state.singleSplitsMapping = TicketSplit ?? state.singleSplitsMapping;
			state.deliveryExclusions = Delivery_Exclusions ?? state.deliveryExclusions;
			state.traitExclusions = TraitExclusions ?? state.traitExclusions;
			state.allowPriceIncrease = AllowPriceIncrease ?? state.allowPriceIncrease;
		},
		toggleExclusions: (state, action: PayloadAction<ToggleExclusionsPayload>) => {
			const { type, payload } = action.payload;
			const key = type === "Exclude Traits" ? "traitExclusions" : "deliveryExclusions";

			const currentExclusions = state[key];
			const exists = currentExclusions.some((x) => x.id === payload.id);
			if (exists) {
				state[key] = currentExclusions.filter((x) => x.id !== payload.id);
				return;
			}
			state[key].push(payload);
		},
		setExclusions: (state, action: PayloadAction<{ type: TGroupName; payload: Array<TExcludeMethodItem> }>) => {
			const { type, payload } = action.payload;
			const key = type === "Exclude Traits" ? "traitExclusions" : "deliveryExclusions";
			state[key] = payload;
		},
		setPriceIncrease: (state, action: PayloadAction<boolean>) => {
			state.allowPriceIncrease = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(saveRules.pending, (state) => {
				state.saveRuleStatus = "pending";
			})
			.addCase(saveRules.fulfilled, (state) => {
				state.saveRuleStatus = "saved";
			})
			.addCase(saveRules.rejected, (state) => {
				state.saveRuleStatus = "error";
			});
	},
});

const _selectSelectedSectionNames = (state: RootState) => state.autopricer.selectedSectionNames;
const _selectSelectedRowNames = (state: RootState) => state.autopricer.selectedRowNames;
const _selectUsersSectionNames = (state: RootState) => state.autopricer.usersSectionNames;
const _selectFeaturesBySection = (state: RootState) => state.autopricer.featuresBySection;
const _selectListingsBySection = (state: RootState) => state.autopricer.listingsBySection;
const _selectSingleSplitsMapping = (state: RootState) => state.autopricer.singleSplitsMapping;

export const selectComparableSections = (state: RootState) => state.autopricer.comparableSections;
export const selectSelectedSections = (state: RootState) => state.autopricer.selectedSectionNames;
export const selectSelectedRows = (state: RootState) => state.autopricer.selectedRowNames;
export const selectCompareAgainstConsignors = (state: RootState) => state.autopricer.compareAgainstConsignors;
export const selectIgnoredOldSeatGeekIds = (state: RootState) => state.autopricer.ignoredOldSeatGeekIds;
export const selectFloorPricePercentage = (state: RootState) => state.autopricer.floorPricePercentage;
export const selectCeilingPricePercentage = (state: RootState) => state.autopricer.ceilingPricePercentage;
export const selectSingleSplitsMapping = (state: RootState) => state.autopricer.singleSplitsMapping;
export const selectMatchAll = (state: RootState) => state.autopricer.matchAll;
export const selectSaveRuleStatus = (state: RootState) => state.autopricer.saveRuleStatus;
export const selectDeliveryExclusions = (state: RootState) => state.autopricer.deliveryExclusions;
export const selectTraitExclusions = (state: RootState) => state.autopricer.traitExclusions;
export const selectAllowPriceIncrease = (state: RootState) => state.autopricer.allowPriceIncrease;

export const selectComparableRows = createSelector(
	[_selectSelectedSectionNames, _selectListingsBySection],
	(selectedSectionNames, listingsBySection) => {
		const selectedSectionListings =
			selectedSectionNames.length > 0
				? mapDictionaryKeysToValues(listingsBySection, selectedSectionNames).flat()
				: Array.from(Object.values(listingsBySection)).flat();

		const listingsByRow = convertMapToRecord(groupByProperty(selectedSectionListings, "row"));

		return getComparableItemsFromDictionary(listingsByRow);
	},
);

export const selectAutopricerMapFillLayers = createSelector(
	[_selectSelectedSectionNames, _selectUsersSectionNames, _selectFeaturesBySection, selectMatchAll],
	(selectedSectionNames, usersSectionNames, featuresBySection, matchAll) => {
		const fillLayers: Array<FillLayer> = [];

		if (matchAll) return fillLayers;

		const selectedSectionsFeatures = mapDictionaryKeysToValues(featuresBySection, selectedSectionNames);
		const usersSectionsFeatures = mapDictionaryKeysToValues(
			featuresBySection,
			difference(usersSectionNames, selectedSectionNames),
		);

		if (selectedSectionsFeatures.length > 0) {
			fillLayers.push(createFillLayer("selected-sections-fill-layer", SELECTED_SECTION_FILL_PAINT, selectedSectionsFeatures));
		}

		if (usersSectionsFeatures.length > 0) {
			fillLayers.push(createFillLayer("users-sections-fill-layer", USERS_SECTION_FILL_PAINT, usersSectionsFeatures));
		}

		return fillLayers;
	},
);

export const selectLowestComparableListings = createSelector(
	[
		_selectSelectedSectionNames,
		_selectSelectedRowNames,
		_selectListingsBySection,
		selectCompareAgainstConsignors,
		_selectSingleSplitsMapping,
		selectDeliveryExclusions,
	],
	(
		selectedSectionNames,
		selectedRowNames,
		listingsBySection,
		compareAgainstConsignors,
		singleSplitsMapping,
		deliveryExclusions,
	) => {
		const excludeInstantDelivery = deliveryExclusions.some((item) => item.label === ExcludeItemLabeEnum.instantDelivery);
		const excludedStockTypes = deliveryExclusions.filter((item) => item.stockType).map((item) => item.stockType);

		return mapDictionaryKeysToValues(listingsBySection, selectedSectionNames)
			.flat()
			.filter(
				(listing) =>
					listing.seatGeekListingId !== INACTIVE_LISTING_ID &&
					selectedRowNames.includes(listing.row) &&
					listing.match !== MatchStatus.USERS_LISTING &&
					(compareAgainstConsignors || listing.match !== MatchStatus.OTHER_CONSIGNOR_LISTING) &&
					(!singleSplitsMapping.length || listing.splits.some((split) => singleSplitsMapping.includes(split))) &&
					(!excludeInstantDelivery || !listing.instantDelivery) &&
					!excludedStockTypes.includes(listing.stockType),
			)
			.sort(sortCompareFnFactory<ActiveSeatGeekListing>("price"));
	},
);

export const selectSeasonLowestComparableListings = createSelector(
	[
		_selectSelectedSectionNames,
		_selectSelectedRowNames,
		selectCompareAgainstConsignors,
		_selectSingleSplitsMapping,
		selectDeliveryExclusions,
		selectSeasonActiveListing,
	],
	(
		selectedSectionNames,
		selectedRowNames,
		compareAgainstConsignors,
		singleSplitsMapping,
		deliveryExclusions,
		seasonActiveListing,
	) => {
		const excludeInstantDelivery = deliveryExclusions.some((item) => item.label === ExcludeItemLabeEnum.instantDelivery);
		const excludedStockTypes = deliveryExclusions.filter((item) => item.stockType).map((item) => item.stockType);

		return seasonActiveListing
			.filter(
				(listing) =>
					listing.seatGeekListingId !== INACTIVE_LISTING_ID &&
					selectedRowNames.includes(listing.row) &&
					selectedSectionNames.includes(listing.section) &&
					listing.match !== MatchStatus.USERS_LISTING &&
					(compareAgainstConsignors || listing.match !== MatchStatus.OTHER_CONSIGNOR_LISTING) &&
					(!singleSplitsMapping.length || listing.splits.some((split) => singleSplitsMapping.includes(split))) &&
					(!excludeInstantDelivery || !listing.instantDelivery) &&
					!excludedStockTypes.includes(listing.stockType),
			)
			.sort(sortCompareFnFactory<ActiveSeatGeekListing>("price"));
	},
);

export const selectLowestComparablePrice = createSelector(
	[
		selectLowestComparableListings,
		selectSeasonLowestComparableListings,
		selectIgnoredOldSeatGeekIds,
		selectViewSeasonLowestComparables,
	],
	(lowestComparableListings, seasonaLowestComparableListings, ignoredOldSeatGeekIds, isSeasonalComparablesOpen) => {
		const pickedListings = isSeasonalComparablesOpen ? seasonaLowestComparableListings : lowestComparableListings;

		return pickedListings.find((listing) => !ignoredOldSeatGeekIds.includes(listing.oldSeatGeekId))?.price ?? 0;
	},
);

export interface SetListingRulesPayload {
	ignores: [];
	listingIdFloors: Array<{
		ListingId: number;
		FloorPrice: number;
		CeilingPrice: number;
	}>;
	rules: {
		ListingId: number;
		SeatGeekListingId: number;
		MasterRuleId: number | null;
		venueId: number;
		AllRows: boolean;
		AllowPriceIncrease: boolean;
		CompareAgainstOwnInventory: boolean;
		CompareToEverything: boolean;
		FloorPrice: number;
		FloorNotification: boolean;
		CeilingPrice: number;
		CeilingNotification: boolean;
		NoComparablesNotification: boolean;
		AmountUnder: number;
		PctUnder: number;
		UseDefaultSplits: true;
		TicketSplit: string;
		Sections: Array<string>;
		Rows: Array<string>;
		TraitExclusions: string;
		Exclude_Outliers: number;
		Delivery_Exclusions: string;
		IgnoredListings: Array<{
			SeatGeekId: string;
			SeatGeekListingId: number;
		}>;
		Ignored_Listings: Array<{
			SeatGeekId: string;
			SeatGeekListingId: number;
		}>;
	};
}

export const setListingRules = createAppAsyncThunk(
	"autopricer/setListingRules",
	async (payload: SetListingRulesPayload, { dispatch, getState }) => {
		try {
			const response = await ApiProvider.default.post<boolean>("api/AutoPricer/SetListingRules", payload);

			const state = getState();
			const eventId = state.pricing.selection.event?.Event_ID;

			const newData = state.pricing.eventInventoryAll.data.map((x) =>
				x.Listing_ID === payload.rules.ListingId ? { ...x, Is_AutoPriced: true } : x,
			);

			dispatch(pricingActions.updateInventoryAll({ data: newData, eventId }));

			return response;
		} catch (error) {
			console.log(error);
		}
	},
);

export type SaveRulesPayload = SetListingRulesPayload["rules"] & {
	RuleName: string;
};

export const saveRules = createAppAsyncThunk(
	"autopricer/saveRules",
	async (payload: SaveRulesPayload) => await ApiProvider.default.post<boolean>("api/AutoPricer/SaveRules", payload),
);

export default autoPricerSlice;
